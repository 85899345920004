import React from 'react';
import classnames from 'classnames';

import ErrorBoundary from '@/src/components/Base/ErrorBoundary';
import NoticeContainer from '@/src/containers/list/top/notice/NoticeContainer';

import SearchBoxContainer from '../../../containers/list/top/searchBoxContainer/async';

const Top = ({ flightWay, globalSwitch, useLowZIndex }) => {
	// 此处不限是否往返组合，即非往返组合也是把低价日历显示在搜索框区域
	const rawRoundTabModeSwitch = flightWay === 'RT' && globalSwitch.get('roundTabModeSwitch');

	/**
	 * 此JS逻辑用于兼容SEO List页面 去除低价日历
	 * <CalenderLowPrice filterV2={true} splitRound={splitRound} />
	 */
	const calendarEl = (
		<ErrorBoundary>
			<div></div>
		</ErrorBoundary>
	);

	return (
		<React.Fragment>
			<div
				className={classnames('header-wrapper header-wrapper-v2', {
					'header-wrapper-multi-v2': flightWay === 'MT',
					'zero-index': useLowZIndex,
				})}
			>
				<ErrorBoundary>
					<NoticeContainer />
				</ErrorBoundary>
				<ErrorBoundary>
					<SearchBoxContainer />
				</ErrorBoundary>
				{rawRoundTabModeSwitch ? calendarEl : null}
			</div>
			{!rawRoundTabModeSwitch ? calendarEl : null}
		</React.Fragment>
	);
};

export default Top;
