import React, { createRef } from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import classnames from 'classnames';

import ErrorBoundary from '@/src/components/Base/ErrorBoundary';

import Top from './top/topSEO';

import Ad from './ad/ad';
import { genOnMouseDown } from '../../actions/channel/channel';
import searchHistoryUbt from '../../ubt/searchHistory';
import { StayTimeoutPopup } from './popups/stayTimeout';
import {
	UbtHandler,
	initUbt,
	UbtBoundary,
	ListUbtGenerator,
	LIST_UBT_GROUP_TYPES,
	LIST_UBT_KEY_TYPES,
} from '../../ubt.v2/list';
import { getPrevCondFromGlobal } from '../../actions/common';
import { SecurityPopup } from './popups/SecurityPopup';

import {
	globalSwitchSelector,
	searchFlightsIsFinishedSelector,
	searchProgressSelector,
	prevCondSelector,
	selectedFlightsSelector,
	comfortTagsSelector,
	isBuildUpSelector,
	stayTimeoutFlagSelector,
	getRouterKeySelector,
	getFixLowPriceCalendarSelector,
	getFixSortBarSelector,
	getSplitRoundFlightsSwitchSelector,
	flightListSelector,
	getAllRecommendFlightsListSelector,
	getPassengerCountArrayFromPrevCondSelector,
	getSearchInfoToUbtSelector,
	getRawSplitRoundFlightsSwitchSelector,
	getRoundTabModeSwitchSelector,
	getActiveRoundTabIndexSelector,
	getShowCityTextSelector,
	getIfScopeInternationalSelector,
	getSearchCriteriaTokenSelector,
	getIfArmySelector,
	currentSegmentSeqSelector,
	getShowAuthCodeSelector,
	showFirstClassPopSelector,
} from '../../containers/list/result/flight/baseSelector';
import { genSetArmy } from '../../actions/list/aside';
import { flightWaySelector } from '../../containers/list/selector/search';
import { Shortcut } from './shortcut/shortcut.js';

import PopDialog from './common/PopDialog.js';

import { fetchSearchHistory, genInitPrevSearchCondSuccess } from '../../actions/list';
import { fetchYearWorkingDays } from '../../actions/common/workday';

import { searchNoResultRecommendSelector } from '../../containers/list/result/recommend/searchNoResultRecommendSelector';
import {
	getClassGradeFilterEnumSelector,
	visibleFlightsCountSelector,
	visibleDirectFlightsCountSelector,
} from '../../containers/list/sidebar/filterbar/filterbarSelector';
import { getSelectFlightItineryIdSelector } from '../../containers/list/result/flightResultSelector';
// import { onRouteSearch } from '../../actions/list/flight'
import { genSetRouterHistory, genSetActiveRoundTabIndex } from '../../actions/list/router';

import { calcSplitRoundSelector } from '../../sources/list/calcSplitRoundStateSelector';
import {
	getIfShowDemandFormSelector,
	getIfShowRoundTabModeSelector,
} from '../../containers/list/result/recommend/recommendSelector';
import { getIfFixSplitFlightsSelector } from '../../containers/list/result/flight/flightSelector';
import { hideBodyOverflow, resumeBodyOverflow } from '../../containers/list/result/syncFixSelectedFlight';

import { getResultInfoUbtSelector } from '../../containers/list/result/recommend/baseRecommendSelector';
import Converter, { SessionHandler } from '../../sources/list/converter';
import { getCityNamesFromPrevCond } from '../../containers/list/result/flight/genHeaderWrapper';
import { getIfAnyAirlineMatrixDataSelector } from '../../containers/list/top/airlineMatrix/airlineMatrixSelector';
import { fetchUserStar } from '../../actions/list/userstar';
// import { getListPageIdByUrl, updatePageId } from '../../sources/common/updatePageId';
// import { IntersectionNotifier } from '../Base/intersetionNotifier';
import { ScopeInternationalProvider } from '../../sources/list/scopeInternationalContext';
import ArmyPopup from './popups/armyPopup';
import { PRICE_SORT_TYPE_GROUP } from '../../constants/list/enums/domesticPriceSortTypeGroup';
import { sendUBT } from '../../sources/common/lightUbt';
import { UBT_KEY } from '../../constants/ubtKeys';
import { getIfScopeInternational } from '../../sources/list/scope';
import { isRenderComplete } from '../../utils/commonUtils';

import AsyncPopupDomesticFlightDetail from '@/src/containers/list/result/PopupFlightDetailContainerV2/async';
import AsyncSelectedFlights from './selectedFlights/async';

// eslint-disable-next-line react/no-unsafe
class ListHome extends React.Component {
	constructor(props) {
		super(props);
		this.onConfirmStayTimeout = () => this.props.onConfirmStayTimeout(this.props.isBuildUpMode);
		this.onSetPopupStatus = this.onSetPopupStatus.bind(this);
		this.setArmy = this.setArmy.bind(this);
		this.setArmyPopupShow = this.setArmyPopupShow.bind(this);
		this.priceSortTypeGroupTitlesRef = {};
		this.createPriceSortTypeGroupRef();
		this.onReSearchSecurity = () => this.props.onReSearchSecurity(this.props.isBuildUpMode);
		this.renderComplete = false;
	}

	state = {
		flightDetailPopup: null,
		poupShow: false,
		showTabRoundChooseDepartTip: false,
		floatLotteryEl: null,
		showArmyPopup: false,
	};
	createPriceSortTypeGroupRef = () => {
		PRICE_SORT_TYPE_GROUP.enums
			.filter((sortType) => {
				return sortType.value.showInSideBar;
			})
			.map((sortType) => {
				this.priceSortTypeGroupTitlesRef[sortType.key] = createRef();
			});
	};

	onGetFloatLotteryEl = ({ el }) => {
		this.setState({ floatLotteryEl: el });
	};

	onMouseDown = (e) => {
		this.props.mouseDown({ target: e.target });
	};

	componentWillMount() {
		let toDeleteCss = document.querySelectorAll(
			'link[href$="webresource.c-ctrip.com/ResUnionOnline/R3/PcHeader/NuoMi/CSS/reset.css"]'
		);
		if (toDeleteCss && toDeleteCss.length > 0) {
			let theLength = toDeleteCss.length;
			for (let i = theLength - 1; i >= 0; i--) {
				toDeleteCss[i].parentNode.removeChild(toDeleteCss[i]);
			}
		}

		searchHistoryUbt.reportSearchHistory();
		initUbt();
		this.props.setRouterHistory(this.props.history);

		setTimeout(() => {
			this.props.fetchYearWorkingDays();
			this.onFetchSearchHistory();
		}, 2000);
		// todo url
		// const pageId = getListPageIdByUrl(location.href)
		// updatePageId(pageId)
	}

	onPopupFlightDetailV2 = ({ flightDetailInfos, currentSegmentSeq }) => {
		const hideFlightDetailPopup = () => {
			resumeBodyOverflow();
			this.setState({ flightDetailPopup: null });
		};
		let { prevCond } = this.props;

		let popup = (
			<PopDialog
				style={Object.assign({ maxHeight: '70%' }, { borderRadius: '4px' })}
				onClickBackground={hideFlightDetailPopup}
				contents={
					<AsyncPopupDomesticFlightDetail
						prevCond={prevCond}
						flightDetailInfos={flightDetailInfos}
						currentSegmentSeq={currentSegmentSeq}
						onClickClose={hideFlightDetailPopup}
					/>
				}
			/>
		);

		hideBodyOverflow();
		this.setState({ flightDetailPopup: popup });
	};

	onFetchSearchHistory = () => {
		this.props.fetchSearchHistory();
	};

	componentDidUpdate(prevProps) {
		if (prevProps.routerKey !== this.props.routerKey) {
			this.onFetchSearchHistory();
		}
		// 列表有内容，则认为列表页加载完成
		if (window && !this.renderComplete && isRenderComplete('flight-list')) {
			window['_nfes_ps_'] = {
				renderEndTime: new Date().getTime(),
			};
			this.renderComplete = true;
		}
	}

	onSetPopupStatus(show) {
		this.setState({
			poupShow: show,
		});
	}

	onSearchArmy(search) {
		this.setState({
			poupShow: search,
		});
	}

	userStarUbt = () => {
		fetchUserStar((data) => {
			localStorage.setItem('userStarData', JSON.stringify(data));
			LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.UserStar = data;
		});
	};

	componentDidMount() {
		this.userStarUbt();

		sendUBT(UBT_KEY.LIST.SHOW, { isInternational: getIfScopeInternational() }, true);
	}

	onClickTabRoundModeDepartTab = () => {
		const { selectFlightItineryId } = this.props;

		if (selectFlightItineryId) {
			this.props.onSetActiveRoundTabIndex(0);
			if (this.state.showTabRoundChooseDepartTip) {
				this.setState({ showTabRoundChooseDepartTip: false });
			}
		}
	};

	onClickTabRoundModeArrivalTab = () => {
		const { activeRoundTabIndex, selectFlightItineryId } = this.props;

		if (activeRoundTabIndex === 0) {
			if (selectFlightItineryId) {
				this.props.onSetActiveRoundTabIndex(1);
			} else {
				if (!this.state.showTabRoundChooseDepartTip) {
					this.setState({ showTabRoundChooseDepartTip: true });
					setTimeout(() => {
						if (this.state.showTabRoundChooseDepartTip) {
							this.setState({ showTabRoundChooseDepartTip: false });
						}
					}, 3000);
				}
			}
		}
	};

	setArmyPopupShow(show) {
		this.setState({
			showArmyPopup: show,
		});
	}

	setArmy(army) {
		this.setArmyPopupShow(false);
		this.props.onSetArmy(army);
	}

	render() {
		const {
			prevCond,
			globalSwitch,
			selectFlightItineryId,
			showDemandForm,
			ubtResultInfo,
			ubtSourceInfo,
			activeRoundTabIndex,
			showCityText,
			flightWay,
			showTabRoundMode,
			scopeInternational,
		} = this.props;

		const onPopupFlightDetail = this.onPopupFlightDetailV2;
		// const onPopupFlightDetail = this.onPopupDomesticFlightDetailV2
		const genContent = () => {
			const selectedFlightEl = (
				<ErrorBoundary>
					<AsyncSelectedFlights
						{...this.props}
						onPopupFlightDetail={onPopupFlightDetail}
						activeRoundTabIndex={activeRoundTabIndex === 1}
					/>
				</ErrorBoundary>
			);
			return <React.Fragment>{!showTabRoundMode && selectedFlightEl}</React.Fragment>;
		};

		const genRoundTabTitle = (segmentSeq) => {
			const departDate = prevCond.getIn(['flightSegments', segmentSeq, 'departureDate']);
			const [shortDepartCityName, shortArrivalCityName] = getCityNamesFromPrevCond({
				isBuildUp: false,
				prevCond,
				showCityText,
				currentSegmentSeq: segmentSeq,
			});

			return (
				<span className="sub_title">
					{shortDepartCityName}
					<span className="round_tab_arrow" />
					{shortArrivalCityName}
					{Converter.remainMonthAndDay(departDate, 'MM-DD')}
					&nbsp;
					{Converter.remianWeek(departDate)}
				</span>
			);
		};

		return (
			<ScopeInternationalProvider value={scopeInternational}>
				<UbtBoundary
					tracker={ListUbtGenerator(
						LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW,
						LIST_UBT_KEY_TYPES.RESULT_INFO,
						ubtResultInfo,
						ubtSourceInfo
					)}
				>
					<div
						className={classnames('main filter-v2', 'zelda_color', 'smooth-v1')}
						onMouseDown={this.onMouseDown}
					>
						<ErrorBoundary>
							<React.Suspense fallback={<div />}>
								<Top
									flightWay={flightWay}
									globalSwitch={globalSwitch}
									useLowZIndex={this.state.showTabRoundChooseDepartTip}
								/>
							</React.Suspense>
						</ErrorBoundary>
						{/* 这里添加广告 */}
						{activeRoundTabIndex === 1 ? null : <Ad />}
						<div className={`body-wrapper  ${showDemandForm ? 'no-result-body-wrapper' : ''}`}>
							{/* 此处需要区分往返分屏 */}
							{showTabRoundMode ? ( //显示需求单、启用分屏往返组合 时候不需要分屏
								<div className="segment_tab_root">
									<UbtBoundary
										tracker={ListUbtGenerator(
											LIST_UBT_GROUP_TYPES.TRIGGER_NOW_CLICK,
											LIST_UBT_KEY_TYPES.TRIGGER_NOW_CLICK,
											'c_round_tab_click_depart'
										)}
									>
										<div
											className={classnames('segment_tab', {
												active: activeRoundTabIndex === 0,
												deactive: activeRoundTabIndex !== 0,
											})}
											onClick={this.onClickTabRoundModeDepartTab}
										>
											{this.state.showTabRoundChooseDepartTip && (
												<span className="tip_bubble">请先选择去程航班</span>
											)}
											<span className="seq_mark">1</span>
											<span className="title">选择去程</span>
											{genRoundTabTitle(0)}
										</div>
									</UbtBoundary>
									<UbtBoundary
										tracker={ListUbtGenerator(
											LIST_UBT_GROUP_TYPES.TRIGGER_NOW_CLICK,
											LIST_UBT_KEY_TYPES.TRIGGER_NOW_CLICK,
											selectFlightItineryId
												? 'c_round_tab_click_arrival'
												: 'c_round_tab_click_disable_arrival'
										)}
									>
										<div
											className={classnames('segment_tab', {
												active: activeRoundTabIndex === 1,
												deactive: selectFlightItineryId && activeRoundTabIndex !== 1,
												disabled: !selectFlightItineryId,
											})}
											onClick={this.onClickTabRoundModeArrivalTab}
										>
											<span className="seq_mark">2</span>
											<span className="title">选择返程</span>
											{genRoundTabTitle(1)}
										</div>
									</UbtBoundary>

									<div className={classnames('flight-list-container')}>
										{genContent(activeRoundTabIndex === 1)}
									</div>
								</div>
							) : (
								genContent(false)
							)}
						</div>
						<ErrorBoundary>
							<Shortcut />
						</ErrorBoundary>

						{this.props.stayTimeoutFlag ? <StayTimeoutPopup onConfirm={this.onConfirmStayTimeout} /> : null}
						{this.props.showFirstClassPop
							? !this.props.showFirstClassPop &&
							  this.props.showAuthCode && <SecurityPopup onReSearch={this.onReSearchSecurity} />
							: this.props.showAuthCode && <SecurityPopup onReSearch={this.onReSearchSecurity} />}
					</div>
				</UbtBoundary>
				{this.state.flightDetailPopup}
				<ArmyPopup
					show={this.state.showArmyPopup}
					cancel={() => this.setArmy(false)}
					confirm={() => {
						this.setArmy(true);
					}}
				/>
			</ScopeInternationalProvider>
		);
	}
}

const mapStateToProps = (state) => {
	const splitRoundFlightsSwitch = getSplitRoundFlightsSwitchSelector(state);

	return {
		scopeInternational: getIfScopeInternationalSelector(state),
		searchFlightsIsFinished: searchFlightsIsFinishedSelector(state),
		searchProgress: searchProgressSelector(state),
		globalSwitch: globalSwitchSelector(state),
		prevCond: prevCondSelector(state),
		selected: selectedFlightsSelector(state),
		selectFlightItineryId: getSelectFlightItineryIdSelector(state),
		comfortTags: comfortTagsSelector(state),
		searchNoResultRecommend: searchNoResultRecommendSelector(state),
		isBuildUpMode: isBuildUpSelector(state),
		classGradeFilterEnum: getClassGradeFilterEnumSelector(state),
		routerKey: getRouterKeySelector(state),
		flightWay: flightWaySelector(state),
		visibleFlightsCount: visibleFlightsCountSelector(state),
		visibleDirectFlightsCount: visibleDirectFlightsCountSelector(state),
		filterV2: true,
		fixSortBar: getFixSortBarSelector(state),
		fixLowPriceCalendar: getFixLowPriceCalendarSelector(state),
		splitRoundFlightsSwitch: false,
		splitRoundFlights: calcSplitRoundSelector(splitRoundFlightsSwitch, flightListSelector, state, false), // 分屏返程开关
		showDemandForm: getIfShowDemandFormSelector(state),
		showTabRoundMode: getIfShowRoundTabModeSelector(state),
		fixSplitFlights: getIfFixSplitFlightsSelector(state),
		allRecommendFlightsList: getAllRecommendFlightsListSelector(state),
		passengerCountArrayFromPrevCond: getPassengerCountArrayFromPrevCondSelector(state),
		stayTimeoutFlag: stayTimeoutFlagSelector(state),
		ubtResultInfo: getResultInfoUbtSelector(state),
		ubtSourceInfo: SessionHandler.getSourceInfo(),
		ubtSearchInfo: getSearchInfoToUbtSelector(state),
		rawSplitRoundFlightsSwitch: getRawSplitRoundFlightsSwitchSelector(state),
		enableAllRecommendSwitch: true,
		roundTabModeSwitch: getRoundTabModeSwitchSelector(state),
		activeRoundTabIndex: getActiveRoundTabIndexSelector(state),
		showCityText: getShowCityTextSelector(state),
		anyMatrixData: getIfAnyAirlineMatrixDataSelector(state),
		searchCriteriaToken: getSearchCriteriaTokenSelector(state),
		army: getIfArmySelector(state),
		currentSegmentSeq: currentSegmentSeqSelector(state),
		showAuthCode: getShowAuthCodeSelector(state),
		showFirstClassPop: showFirstClassPopSelector(state),
	};
};

const mapDispatchToProps = (dispatch) => ({
	mouseDown(e) {
		dispatch(genOnMouseDown(e));
	},
	fetchSearchHistory: () => {
		dispatch(fetchSearchHistory());
	},
	fetchYearWorkingDays: () => {
		dispatch(fetchYearWorkingDays());
	},
	// searchFlights: (selectedflight, routeSearchCriteria, prevCond, targetSegmentSeq, isBuildUpMode, classGradeFilterEnum) => {
	//     dispatch(onRouteSearch(selectedflight, routeSearchCriteria, prevCond, targetSegmentSeq, isBuildUpMode, classGradeFilterEnum))
	// },
	setRouterHistory: (history) => {
		dispatch(genSetRouterHistory(history));
	},
	onConfirmStayTimeout: () => {
		UbtHandler.onUploadData('c_click', {
			text: '列表页停留时间过长弹窗，点击 [我知道了] 按钮',
		});
		let prevCond = getPrevCondFromGlobal(),
			query = qs.parse(
				location.search && location.search[0] === '?' ? location.search.substring(1) : location.search
			);

		if (prevCond) {
			dispatch(genInitPrevSearchCondSuccess(prevCond, query, { needResetAll: true }));
		}
		// dispatch(genSetStayTimeoutFlag(0))
		// dispatch(genBeforeRouteSearch(true))
		// dispatch(fetchSearchFlights(prevCond, isBuildUpMode ? 1 : 0, isBuildUpMode, false))
	},
	onSetActiveRoundTabIndex: (acitveSegmentSeq) => {
		dispatch(genSetActiveRoundTabIndex(acitveSegmentSeq));
	},
	onSetArmy: (army) => {
		dispatch(genSetArmy(army));
	},
	onReSearchSecurity: () => {
		UbtHandler.onUploadData('c_reSearch_click', {
			text: '反爬虫验证框，点击 [重新搜索] 按钮',
		});
		let prevCond = getPrevCondFromGlobal(),
			query = qs.parse(
				location.search && location.search[0] === '?' ? location.search.substring(1) : location.search
			);

		if (prevCond) {
			dispatch(genInitPrevSearchCondSuccess(prevCond, query, { needResetAll: true }));
		}
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ListHome);
